// src/Chart/Chart.js

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import { format } from 'date-fns';
import './chart.scss'; 

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const barColors = [
    '#AD9C81', '#D3B78A', '#918A80', '#C09B62', '#C4AA82',
    '#AE9D81', '#BBAA90', '#BA975F', '#E4D2B6', '#C4AA82'
];

const getFirstDayOfPreviousMonth = () => {
    const now = new Date();
    return format(new Date(now.getFullYear(), now.getMonth() - 1, 1), 'yyyy-MM-dd');
};

const getCurrentDate = () => {
    return format(new Date(), 'yyyy-MM-dd');
};

const formatNumber = (value) => {
    return value.toLocaleString();
};

const Chart = ({ zoneName }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [startDate, setStartDate] = useState(getFirstDayOfPreviousMonth());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [municipality, setMunicipality] = useState('');
    const [municipalities, setMunicipalities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [noData, setNoData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            setNoData(false);
            try {
                const municipalityFilter = municipality ? `and municipality_name="${municipality}"` : '';
                const response = await axios.get(
                    `https://www.data.gov.qa/api/explore/v2.1/catalog/datasets/weekly-real-estate-newsletter/records?where=date_of_contract>="${startDate}" and date_of_contract<="${endDate}" and zone_name LIKE "%${zoneName}%" ${municipalityFilter}&limit=10`
                );
                let results = response.data.results;

                if (results.length === 0) {
                    setNoData(true);
                } else {
                    results = results.sort((a, b) => new Date(b.date_of_contract) - new Date(a.date_of_contract)).slice(0, 10);

                    const labels = results.map(record => format(new Date(record.date_of_contract), 'MMM-dd'));
                    const dataValues = results.map(record => record.real_estate_value);
                    const zoneNames = results.map(record => record.zone_name || 'Unknown Zone');
                    const datapricepersqft = results.map(record => record.price_per_square_foot || 0);
                    const areainsqmeter = results.map(record => record.area_in_square_meters || 0);

                    setChartData({
                        labels: labels,
                        datasets: [
                            {
                                label: 'Real Estate Value',
                                data: dataValues,
                                backgroundColor: barColors.slice(0, dataValues.length),
                                borderColor: barColors.slice(0, dataValues.length).map(color => color.replace('0.2', '1')),
                                borderWidth: 1,
                                zoneNames: zoneNames,
                                pricePerSquareFoot: datapricepersqft,
                                areaInSquareMeters: areainsqmeter,
                            },
                        ],
                    });

                    const municipalityList = Array.from(new Set(results.map(record => record.municipality_name || '')));
                    setMunicipalities(municipalityList);
                }
            } catch (error) {
                setError('Error fetching data.');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate, zoneName, municipality]);

    const getDatalabelsOptions = () => {
        const width = window.innerWidth;
        if (width < 480) {
            return {
                display: true,
                color: '#000',
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    const areaInSquareMeters = chartData.datasets[0]?.areaInSquareMeters[context.dataIndex] || 0;
                    return `${formatNumber(areaInSquareMeters)} sq.m`;
                },
                font: {
                    weight: 'bold',
                    size: 5, 
                },
            };
        } else if (width < 768) {
            return {
                display: true,
                color: '#000',
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    const areaInSquareMeters = chartData.datasets[0]?.areaInSquareMeters[context.dataIndex] || 0;
                    return `${formatNumber(areaInSquareMeters)} sq.m`;
                },
                font: {
                    weight: 'bold',
                    size: 10, 
                },
            };
        } else {
            return {
                display: true,
                color: '#000',
                anchor: 'end',
                align: 'top',
                formatter: (value, context) => {
                    const areaInSquareMeters = chartData.datasets[0]?.areaInSquareMeters[context.dataIndex] || 0;
                    return `${formatNumber(areaInSquareMeters)} sq.m`;
                },
                font: {
                    weight: 'bold',
                    size: 12, 
                },
            };
        }
    };

    return (
        <div className="chart-container">
            <div className="controls">
                <label htmlFor="start-date">Start: </label>
                <input
                    type="date"
                    id="start-date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <label htmlFor="end-date">End: </label>
                <input
                    type="date"
                    id="end-date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
                <label htmlFor="municipality">Municipality: </label>
                <select
                    id="municipality"
                    value={municipality}
                    onChange={(e) => setMunicipality(e.target.value)}
                >
                    <option value="">Select Municipality</option>
                    {municipalities.map((muni, index) => (
                        <option key={index} value={muni}>{muni}</option>
                    ))}
                </select>
            </div>

            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {noData && <p>No data available for the selected criteria.</p>}
            {!loading && !error && !noData && (
                <Bar
                    data={{
                        labels: chartData.labels,
                        datasets: [
                            {
                                label: chartData.datasets[0]?.label || 'Real Estate Value',
                                data: chartData.datasets[0]?.data || [],
                                backgroundColor: chartData.datasets[0]?.backgroundColor || [],
                                borderColor: chartData.datasets[0]?.borderColor || [],
                                borderWidth: 1,
                            },
                        ],
                    }}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        const index = tooltipItem.dataIndex;
                                        const realEstateValue = chartData.datasets[0].data[index];
                                        const areaInSquareMeters = chartData.datasets[0].areaInSquareMeters[index];
                                        const pricePerSquareFoot = chartData.datasets[0].pricePerSquareFoot[index];

                                        return [
                                            `Real Estate Value: ${formatNumber(realEstateValue)}`,
                                            `Area in Square Meters: ${formatNumber(areaInSquareMeters)}`,
                                            `Price per Square Foot: ${formatNumber(pricePerSquareFoot)}`,
                                        ];
                                    },
                                },
                                backgroundColor: '#333',
                                titleFont: {
                                    size: 16,
                                },
                                bodyFont: {
                                    size: 14,
                                },
                            },
                            datalabels: getDatalabelsOptions(), 
                        },
                        scales: {
                            x: {
                                type: 'category',
                                title: {
                                    display: true,
                                    text: 'Date Of Contract',
                                },
                                ticks: {
                                    maxRotation: 90,
                                    minRotation: 0,
                                },
                            },
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: 'Real Estate Value',
                                },
                                ticks: {
                                    callback: function(value) {
                                        return formatNumber(value);
                                    }
                                }
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Chart;
