import { Link } from "@StarberryUtils";
import React, {useState} from "react"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"
import QuestionIcon from "../../images/question-icon.svg"
import algoliasearch from 'algoliasearch/lite';
import "./CountSection.scss"
import {
  InstantSearch,
  Configure,
  Hits,
  Highlight,
  connectSearchBox,
  RefinementList
} from 'react-instantsearch-dom';

import { useStaticQuery, graphql } from "gatsby"

import {CommunityCounterSale} from "../../queries/common_use_query"
import { removeDataAttributes } from "../../comQueryStructure";


const searchClient = algoliasearch(
	`${process.env.GATSBY_ALGOLIA_APP_ID}`,
	`${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
);

const CountSection = props => {

  //console.log("Area_Name", props.Area_Name);
  const [saleCount, setSaleCount] = useState(0)
  const [rentCount, setRentCount] = useState(0)
  const {loading:commu_count_loading, error:commu_count_error, data:community_count_data} = CommunityCounterSale(props.Area_Name);
  const commu_sale_new_count_data = removeDataAttributes(community_count_data?.newProjectsSale)
  const commu_rent_new_count_data = removeDataAttributes(community_count_data?.newProjectsRent)
  const commu_sale_count_data = removeDataAttributes(community_count_data?.propertiesSale)
  const commu_rent_count_data = removeDataAttributes(community_count_data?.propertiesRent)

  const commu_count_data = {newProjectsSale: commu_sale_new_count_data, newProjectsRent:commu_rent_new_count_data, propertiesSale: commu_sale_count_data, propertiesRent:commu_rent_count_data}

  const index = searchClient.initIndex(`${process.env.GATSBY_ALGOLIA_INDEX_NAME}`);
  let propertiesSaleCount = null;
  let propertiesRentcount = null;
  
  // Search for "query string" in the index "contacts"
  index.search(props.get_url,{  
    filters: `publish:true AND searchType:sales AND department:residential AND (status:"for sale" OR status:"sold")` 
  }).then(({ nbHits }) => {
    console.log("countsectionprops",nbHits);
    setSaleCount(nbHits) 
  });

  index.search(props.get_url,{  
    filters: `publish:true AND searchType:lettings AND department:residential AND (status:"for rent" OR status:"rented")` 
  }).then(({ nbHits }) => {
    setRentCount(nbHits) 
  });

  // var counter_data = data.glstrapi.globalModule.District_Stats_Block;

  // console.log("commu_count_data", commu_count_data);
  console.log("countsectionprops",propertiesSaleCount, propertiesRentcount);

  return (
    
    commu_count_loading ? '' : commu_count_data && ((commu_count_data.newProjectsSale && commu_count_data.newProjectsSale.length > 0) || (commu_count_data.propertiesSale && commu_count_data.propertiesSale.length > 0) || (commu_count_data.newProjectsRent && commu_count_data.newProjectsRent.length > 0) || (commu_count_data.propertiesRent && commu_count_data.propertiesRent.length > 0)) ? <>
    <div className="count-section">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="count-section-title">
               <div className="animated">
                <h2>The Pearl Gates showcase a large variety of property in this {props.Area_Name},{props.district} community. Browse through our exclusive collection and get in touch with our friendly team.
                </h2>
               </div>
            </div>
            <div className="count-section-box">
              {/* {
                props.Counter_Block && props.Counter_Block.length > 0 && props.Counter_Block.map((item, index) => {
                  return(
                     <div className="animated" delay={150 + (index*100)} >
                      <div className="count-section-item">
                        <div className="">
                          <strong>{item.Count}</strong>
                          <p>{item.Name}</p>
                          <Link to={item.CTA_Link && item.CTA_Link} className="tracking-btn">{item.CTA_Label}</Link>
                        </div>
                      </div>
                     </div>
                  )
                })
              } */}
              
              {
                commu_count_data.newProjectsSale && commu_count_data.newProjectsSale.length > 0 &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{commu_count_data.newProjectsSale.length}</strong>
                      <p>New Projects for Sale</p>
                      <Link to={`/qatar-property-services/buy-property/view-for-our-latest-projects/#${commu_count_data.newProjectsSale[0].Name.replace(/ /g,'-')}`} className="tracking-btn">View Projects</Link>
                    </div>
                  </div>
                 </div>
              }

              {
                saleCount>0 && 
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{saleCount}</strong>
                      <p>Properties for sale in {props.Area_Name},{props.district}</p>
                      <Link to={`/properties/for-sale/in-${props.get_url.replace('-commercial','').replace('-residential','')}`} className="tracking-btn">View Properties</Link>
                    </div>
                  </div>
                 </div>
              }

              {
                commu_count_data.newProjectsRent && commu_count_data.newProjectsRent.length > 0 &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{commu_count_data.newProjectsRent.length}</strong>
                      <p>New Projects for Rent</p>
                      <Link to={`/qatar-property-services/rent-a-property/view-for-our-latest-projects-rent/area-${props.Area_Name.replace(/ /g,'-')}`} className="tracking-btn">View Projects</Link>
                    </div>
                  </div>
                 </div>
              }

              {
                rentCount &&
                 <div className="animated" delay={150} >
                  <div className="count-section-item">
                    <div className="">
                      <strong>{rentCount}</strong>
                      <p>Properties for Rent in {props.Area_Name},{props.district}</p>
                      <Link to={`/properties/for-rent/in-${props.get_url.replace('commercial','').replace('residential','')}`} className="tracking-btn">View Properties</Link>
                    </div>
                  </div>
                 </div>
              }

            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </> : ''
  )
}

export default CountSection
